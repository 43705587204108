import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { IErrorMessage } from 'redux/store.types';

export const getPlayerCasinoData = createAsyncThunk(
  'casino/getPlayerCasinoData',
  async (data: any) => {
    const response = await axios.post('/player/list-player-casino-sessions', {
      ...data,
    });
    return response.data;
  },
);

export const getAggregatorsThunks = createAsyncThunk(
  'casino/getAggregatorsThunks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post('/player/list-casino-aggregators');
      return response.data;
    } catch (err) {
      const error = err as AxiosError<IErrorMessage>;
      toast.error('You can not get aggregators form backend', {
        position: toast.POSITION.TOP_CENTER,
      });
      return rejectWithValue(error.response?.data.message);
    }
  },
);

export const getCasinoBetsBySessionThunks = createAsyncThunk(
  'casino/getCasinoBetsBySessionThunks',
  async (data: any) => {
    const response = await axios.post(
      '/player/list-player-casino-bets-by-session',
      {
        ...data,
      },
    );
    return response.data;
  },
);
