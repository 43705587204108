/* eslint-disable */

import React, { FC, useEffect } from 'react';
import { Button, Input, Modal, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  createPlayerMessageThunk,
  updatePlayerMessageThunk,
} from 'redux/reducers/players/message/playerMessageThunk';
import { useParams } from 'react-router-dom';

interface ModalProps {
  handleModalClose: () => void;
  isModalVisible: boolean;
  handleEditFormSubmit: (values: any) => void;
  editingRecord: any;
}

const MessageModal: FC<ModalProps> = ({
  handleModalClose,
  isModalVisible,
  handleEditFormSubmit,
  editingRecord,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (editingRecord) {
      form.setFieldsValue({
        message_title: editingRecord.message?.message_title,
        message_body: editingRecord.message?.message_body,
      });
    } else {
      form.resetFields();
    }
  }, [editingRecord, form, isModalVisible]);

  const handleCancel = () => {
    handleModalClose();
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then(values => {
        const action: any = editingRecord?.message_id
          ? updatePlayerMessageThunk({
              data: {
                message_title: values.message_title,
                message_body: values.message_body,
              },
              message_id: editingRecord.message_id,
            })
          : createPlayerMessageThunk({
              message_title: values.message_title,
              message_body: values.message_body,
              player_id: id,
            });

        dispatch(action).then(() => {
          handleEditFormSubmit(values);
          form.resetFields();
        });
      })
      .catch(info => {
        toast.error('Error: Please check the form fields');
      });
  };

  return (
    <Modal
      title={editingRecord ? t('Edit Record') : t('New Message')}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}>
      <Form form={form} layout='vertical' defaultValue={editingRecord}>
        <Form.Item
          label='TITLE'
          name='message_title'
          rules={[{ required: true, message: 'Please input the title!' }]}>
          <Input id='message_title' name='message_title' />
        </Form.Item>
        <Form.Item
          label='MESSAGE'
          name='message_body'
          rules={[{ required: true, message: 'Please input the message!' }]}>
          <Input.TextArea rows={4} name='message_body' />
        </Form.Item>
        <Form.Item>
          <Button type='primary' onClick={handleFormSubmit}>
            {editingRecord ? t('Save') : t('Send')}
          </Button>
          <Button onClick={handleCancel}>{t('Cancel')}</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MessageModal;
