import dayjs from 'dayjs';

import { formatNumberWithCommas } from 'components/Routes/Players/players.service';

import classes from '../TRXTab.module.scss';
import TRXModal from '../components/TRXModal/TRXModal';

import { ITRXTable } from './TRXTab.types';

export const trxStatusTypeOption = [
  { title: 'Pending', value: 'PENDING' },
  { title: 'Approved', value: 'APPROVED' },
  { title: 'Denied', value: 'DENIED' },
  { title: 'Success', value: 'SUCCESS' },
  { title: 'Canceled', value: 'CANCELED' },
  { title: 'WaitGW', value: 'WAITGW' },
];

export const trxGlobalTypeOption = [
  {
    value: 'All',
    key: ['Deposit', 'Withdraw'],
  },
  {
    value: 'Deposit',
    key: 'deposit',
  },
  {
    value: 'Withdraw',
    key: 'withdraw',
  },
];

export const trxApprovedTypeTreeOptions = [
  { title: 'PENDING', value: 'PENDING', key: 'PENDING' },
  { title: 'APPROVED', value: 'APPROVED', key: 'APPROVED' },
  { title: 'REJECTED', value: 'REJECTED', key: 'REJECTED' },
];

export const dataInOut = [
  {
    key: '1',
    label1: 'In',
    data1: '...data',
    label2: 'Total In',
    data2: '...data',
  },
  {
    key: '2',
    label1: 'Out',
    data1: '...data',
    label2: 'Total Out',
    data2: '...data',
  },
];

export const columnsInOut = [
  {
    title: '',
    dataIndex: 'label1',
    key: 'label1',
    render: (text: string) => (
      <span
        style={{ display: 'block', textAlign: 'center', fontWeight: 'bold' }}>
        {text}
      </span>
    ),
  },
  {
    title: '',
    dataIndex: 'data1',
    key: 'data1',
    render: (text: string) => (
      <span style={{ display: 'block', textAlign: 'center' }}>{text}</span>
    ),
  },
  {
    title: '',
    dataIndex: 'label2',
    key: 'label2',
    render: (text: string) => (
      <span
        style={{ display: 'block', textAlign: 'center', fontWeight: 'bold' }}>
        {text}
      </span>
    ),
  },
  {
    title: '',
    dataIndex: 'data2',
    key: 'data2',
    render: (text: string) => (
      <span style={{ display: 'block', textAlign: 'center' }}>{text}</span>
    ),
  },
];

export const trxTabColumns = [
  {
    title: 'TRX ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'TYPE',
    dataIndex: 'op_type',
    key: 'op_type',
  },
  {
    title: 'APP',
    dataIndex: 'app_type',
    key: 'app_type',
  },
  {
    title: 'AMOUNT',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => formatNumberWithCommas(amount),
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'PAYMENT TRX ID',
    dataIndex: 'gateway_response',
    key: 'gateway_response',
  },
  {
    title: 'CREATED',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (createdAt: string) =>
      dayjs(createdAt).utc().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'UPDATED',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (updatedAt: string) =>
      dayjs(updatedAt).utc().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    title: 'ACTIONS',
    key: 'actions',
    render: (record: any) => {
      if (record.status === 'SUCCESS' && record.op_type.startsWith('IN-')) {
        return <TRXModal record={record} />;
      }
      return null;
    },
  },
];

export const getRowClassName = (record: ITRXTable) => {
  const statusColors: { [key: string]: string } = {
    PENDING: classes.blue,
    DENIED: classes.red,
    SUCCESS: classes.green,
    CANCELED: classes.gray,
    APPROVED: classes.yellow,
  };

  const color = statusColors[record.status];
  return color || '';
};
