import { Col } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './TRXModal.module.scss';

interface ModalProps {
  data: any;
  currentTrx: any;
  newUser: boolean;
}

const ModalUserData: React.FC<ModalProps> = ({ data, currentTrx, newUser }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col span={10} className={classes.modalCol}>
        <p>
          {t('User ID')} -
          <span
            className={
              newUser ? classes.userInfoDataNew : classes.userInfoData
            }>
            {data.id}
          </span>
        </p>
        <p>
          {t('Phone number')}-
          <span
            className={
              newUser ? classes.userInfoDataNew : classes.userInfoData
            }>
            {data.phone}
          </span>
        </p>
        {/* <p>
    {t('Additional Phone number')}-{}
  </p> */}
        <p>
          {t('Balance')}-
          <span
            className={
              newUser ? classes.userInfoDataNew : classes.userInfoData
            }>
            {data.balance}
          </span>
        </p>
        <p>
          {t('Transaction ID')}-
          <span
            className={
              newUser ? classes.userInfoDataNew : classes.userInfoData
            }>
            {currentTrx?.id}
          </span>
        </p>
        <p>
          {t('Payment TRX ID')}-
          <span
            className={
              newUser ? classes.userInfoDataNew : classes.userInfoData
            }>
            {currentTrx?.paymentTrxId}
          </span>
        </p>
        <p>
          {t('Transaction amount')}-
          <span
            className={
              newUser ? classes.userInfoDataNew : classes.userInfoData
            }>
            {' '}
            {currentTrx?.amount}
          </span>
        </p>
      </Col>
    </>
  );
};

export default ModalUserData;
