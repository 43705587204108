/* eslint-disable */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  deleteMessageThunk,
  getPlayerMessageThunk,
} from './playerMessageThunk';

interface Message {
  message_id: string;
  message_title: string;
  message_body: string;
}

interface MessageState {
  message: {
    list: Message[];
    count: string;
  };
  loading: boolean;
  error: string | null;
}

interface UpdateMessagePayload {
  message_id: string;
  data: Partial<Message>;
}

const initialState: MessageState = {
  message: {
    list: [],
    count: '',
  },
  loading: false,
  error: null,
};

const playerMessageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    updateMessage: (state, action: PayloadAction<any>) => {
      const { message_id, data } = action.payload;
      const index = state.message.list.findIndex(
        message => message.message_id === message_id,
      );
      if (index !== -1) {
        state.message.list[index] = data;
      }
    },
    addNewMessage: (state, action: PayloadAction<Message>) => {
      state.message.list.unshift(action.payload);
    },
    deleteMessage: (state, action: PayloadAction<string>) => {
      const message_id = action.payload;
      const index = state.message.list.findIndex(
        message => message.message_id === message_id,
      );
      if (index !== -1) {
        state.message.list.splice(index, 1);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPlayerMessageThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlayerMessageThunk.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);

        state.message.list = action.payload.rows;
        state.message.count = action.payload.count;
      })
      .addCase(getPlayerMessageThunk.rejected, state => {
        state.loading = false;
      })
      .addCase(deleteMessageThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteMessageThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteMessageThunk.rejected, state => {
        state.loading = false;
      });
  },
});

export const { updateMessage, addNewMessage, deleteMessage } =
  playerMessageSlice.actions;
export default playerMessageSlice.reducer;
