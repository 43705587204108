/* eslint-disable */

import { FC, useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';

import SportTabFilter from './components/SportTabFilter/SportTabFilter';
import SportTabSmallTable from './components/SPortTabSmallTable/SportTabSmallTable';
import { sportTabGeneralTableColumn } from './helpers/costants';
import { ISportTabGeneralTable } from './helpers/SportTab.type';
import { InfoCircleOutlined } from '@ant-design/icons';
import SpTabGenTableModal from './components/SportTabGeneralTable/SpTabGenTableModal/SpTabGenTableModal';
import { useSelector } from 'react-redux';
import classes from '../SportTab/components/SportTabGeneralTable/SportTabGeneralTable.module.scss';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { getPlayerSportThunk } from 'redux/reducers/players/sport/playerSportThunk';
import { useParams } from 'react-router-dom';

const SportTab: FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { sport, loading } = useSelector(
    (state: any) => state.playerSportSlice,
  );
  const [selectedBets, setSelectedBets] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(50);
  const [totalOdds, setTotalOdds] = useState(0);
  const { logId, date } = useSelector(
    (state: any) => state.players.choosenBetSportData,
  );
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const handleSessionClick = (record: any) => {
    setIsModalVisible(true);
    setTotalOdds(record.total_odds);
    setSelectedBets(record?.bets);
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const columnsWithInfoIcon = [
    ...sportTabGeneralTableColumn,
    {
      title: 'Info',
      dataIndex: 'info',
      key: 'info',
      width: 50,
      render: (_: any, record: ISportTabGeneralTable) => (
        <InfoCircleOutlined
          className={classes.infoIcon}
          onClick={() => handleSessionClick(record)}
        />
      ),
    },
  ];

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (logId) {
      const datee = new Date(date);
      if (!isNaN(datee.getTime())) {
        const oneDayBefore = new Date(datee);
        oneDayBefore.setDate(datee.getDate() - 1);
        const oneDayAfter = new Date(datee);
        oneDayAfter.setDate(datee.getDate() + 1);

        dispatch(
          getPlayerSportThunk({
            limit: pageSize,
            page: currentPage,
            filters: {
              date_from: oneDayBefore.toISOString(),
              date_to: oneDayAfter.toISOString(),
              player_id: id,
              ztype: [],
              status: [],
              money_options: [],
              package_id: logId,
            },
          }),
        ).then(() => {
          setIsModalVisible(true);
        });
      }
    }
  }, [logId]);

  return (
    <>
      <Row gutter={[2, 12]}>
        <Col span={24}>
          <SportTabFilter
            pageSize={pageSize}
            currentPage={currentPage}
            choosenId={logId}
            id={id}
          />
        </Col>
        <Col style={{ marginTop: 20 }} span={24}>
          <SportTabSmallTable />
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            size='middle'
            dataSource={sport.list}
            bordered
            scroll={{ x: 1200, y: 420 }}
            columns={columnsWithInfoIcon}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: sport.count,
              pageSizeOptions: ['10', '20', '50', '100'],
              showQuickJumper: true,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
          <SpTabGenTableModal
            isModalVisible={isModalVisible}
            handleModalClose={handleModalClose}
            bets={sport.list.length <= 1 ? sport.list[0]?.bets : selectedBets}
            totalOdds={
              sport.list.length <= 1 ? sport.list[0]?.total_odds : totalOdds
            }
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default SportTab;
