/* eslint-disable */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

import {
  addNewMessage,
  deleteMessage,
  updateMessage,
} from './playerMessageSlice';

interface CreatePlayerMessageParams {
  message_title: string;
  message_body: string;
  player_id: string | undefined;
}

export const getPlayerMessageThunk = createAsyncThunk(
  'message/getPlayerMessageThunk',
  async (data: any) => {
    const response = await axios.post('/player/list-text-messages', {
      ...data,
    });
    return response.data;
  },
);

export const updatePlayerMessageThunk = createAsyncThunk(
  'message/updatePlayerMessageThunk',
  async (
    data: {
      data: { message_title: string; message_body: string };
      message_id: string;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await axios.post('/player/update-player-text-message', {
        ...data,
      });
      console.log(response.data);

      dispatch(
        updateMessage({
          message_id: data.message_id,
          data: response.data,
        }),
      );
      toast.success('Message updated successfully', {
        position: toast.POSITION.TOP_CENTER,
      });
      return response.data;
    } catch (error: any) {
      toast.error('Error updating message', {
        position: toast.POSITION.TOP_CENTER,
      });
      return rejectWithValue(error.response.data);
    }
  },
);

export const createPlayerMessageThunk = createAsyncThunk(
  'message/createPlayerMessageThunk',
  async (
    { message_title, message_body, player_id }: CreatePlayerMessageParams,
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await axios.post('/player/create-text-message', {
        player_id,
        message_title,
        message_body,
      });
      console.log(response);
      dispatch(addNewMessage(response.data));
      toast.success('Message updated successfully', {
        position: toast.POSITION.TOP_CENTER,
      });
      return 'response.data';
    } catch (error: any) {
      toast.error('Error updating message', {
        position: toast.POSITION.TOP_CENTER,
      });
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteMessageThunk = createAsyncThunk(
  'message/deleteMessageThunk',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/player/delete-player-text-message', {
        message_id: id,
      });
      dispatch(deleteMessage(id));
      toast.success('Message Deleted successfully', {
        position: toast.POSITION.TOP_CENTER,
      });
      return response.data;
    } catch (error: any) {
      toast.error('Error Deleting message', {
        position: toast.POSITION.TOP_CENTER,
      });
      return rejectWithValue(error.response.data);
    }
  },
);
