/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

import {
  fixBalanaceLogThunk,
  getBalanaceLogThunk,
  getSportCasinoGamesData,
} from './balanceLogThunk';

const initialState = {
  data: { list: [], count: 0, lastDepositLog: {}, lastWithdrawLog: {} },
  sportCasinoGames: {
    sport: {},
    casino: {},
    games: {},
  },
  fix: {
    error: {} || null,
    isUpdate: false,
  },
  loading: false,
  error: null as string | null,
};

const balanceLogSlice = createSlice({
  name: 'balanceLog',
  initialState,
  reducers: {
    setIsUpdate: (state, action) => {
      state.fix.isUpdate = action.payload;
    },
    setError: (state, action) => {
      state.fix.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getBalanaceLogThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBalanaceLogThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.list = action.payload.list;
        state.data.count = action.payload.count;
        state.data.lastDepositLog = action.payload.last_deposit_log;
        state.data.lastWithdrawLog = action.payload.last_withdraw_log;
      })
      .addCase(getBalanaceLogThunk.rejected, state => {
        state.loading = false;
      })
      .addCase(getSportCasinoGamesData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportCasinoGamesData.fulfilled, (state, action) => {
        state.loading = false;
        state.sportCasinoGames.sport = action.payload.sport;
        state.sportCasinoGames.casino = action.payload.casino;
        state.sportCasinoGames.games = action.payload.games;
      })
      .addCase(getSportCasinoGamesData.rejected, state => {
        state.loading = false;
      })
      .addCase(fixBalanaceLogThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fixBalanaceLogThunk.fulfilled, state => {
        state.loading = false;
      })
      .addCase(fixBalanaceLogThunk.rejected, state => {
        state.loading = false;
      });
  },
});

export const { setIsUpdate, setError } = balanceLogSlice.actions;
export default balanceLogSlice.reducer;
