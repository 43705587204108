import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

export const shortTransactionData = createAsyncThunk(
  'transaction/fetchData',
  async (requestData: any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/transaction/list', requestData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateTransactionStatus = async (
  transactionsIds: number[],
  status: string,
) => {
  try {
    const response = await axios.post('/transaction/update-status', {
      status,
      transactionsIds,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating status:', error);
  }
};

export const checkTransactions = createAsyncThunk(
  'transactions/check',
  async (filters: any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/transaction/bulk-manual-check', {
        ...filters,
      });

      toast.success('Bulk Fixed successful', {
        position: toast.POSITION.TOP_CENTER,
      });

      return response.data;
    } catch (error: any) {
      toast.error(
        `Error: ${error.response?.data?.message || 'An error occurred'}`,
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );

      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  },
);
