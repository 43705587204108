import { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { selectPhoneNumberCode } from 'redux/reducers/projects/projects.slice';
import { IErrorMessage } from 'redux/store.types';

import { ITRXFilter } from '../helpers/Transactions.types';
import {
  IInBoForm,
  IInBoRequest,
  IInBoResponse,
} from '../InBoModal/inBo.types';
import { filtersForUserIdOrNumber } from '../helpers/Constans';

import Classes from './OutBoModal.module.scss';

type PropTypes = {
  TRXfilters: ITRXFilter[] | undefined;
  isOutBoModalOpen: boolean;
  setIsOutBoModalOPen: (x: boolean) => void;
};

const OutBoModal: FC<PropTypes> = ({
  TRXfilters,
  setIsOutBoModalOPen,
  isOutBoModalOpen,
}) => {
  const [form] = Form.useForm();
  const [selectedOption, setSelectedOption] = useState<string>('usersInput');
  const phoneNumberCode = useSelector(selectPhoneNumberCode);
  const [selectedOpType, setSelectedOpType] = useState<string>('');
  const [isAwaAffected, setIsAwaAffected] = useState<boolean>(false);

  const handleOpTypeChange = (value: string) => {
    setSelectedOpType(value);
  };

  useEffect(() => {
    if (!isOutBoModalOpen) {
      form.resetFields();
    }
  }, [isOutBoModalOpen]);

  const mutation = useMutation({
    mutationFn: (data: IInBoRequest) => {
      const { token, ...rest } = data;
      return axios.post<IInBoResponse>('/transaction/insert', rest, {
        headers: {
          'x-tf-token': token,
        },
      });
    },
    onSuccess: res => {
      const requestBody: IInBoRequest = JSON.parse(res.config.data);
      const requestedIds = requestBody.usersIds;
      const invalidIds = res.data.missingPlayersIds;
      setIsOutBoModalOPen(false);
      form.resetFields();
      if (requestedIds?.length !== invalidIds?.length) {
        const insertedIds = !invalidIds?.length
          ? requestedIds
          : requestedIds.filter(id => !invalidIds.includes(id));
        toast.success(
          `${t(
            'Тhese IDs successfully inserted',
          )} - ${insertedIds?.toString()}`,
          { position: toast.POSITION.TOP_CENTER },
        );
      }
      if (invalidIds?.length) {
        toast.error(
          `${t("Тhese IDs don't exist")} - ${invalidIds.toString()}`,
          { autoClose: false, position: toast.POSITION.TOP_CENTER },
        );
      }
    },
    onError: err => {
      const error = err as unknown as AxiosError<IErrorMessage>;
      toast.error(error.response?.data.message || t('Something went wrong'), {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  });

  const filters = TRXfilters?.map(item => ({ value: item.name }));
  const paymentTrxIdRequiredLevel = TRXfilters?.filter(
    item => item.name === selectedOpType,
  ).map(item => item?.payment_trx_id_required_level)[0];
  const reasonRequiredLevel = TRXfilters?.filter(
    item => item.name === selectedOpType,
  ).map(item => item?.reason_required_level)[0];

  const onFinish = (data: IInBoForm) => {
    const requestBody: IInBoRequest = {
      amount: data.amount,
      opType: data.opType,
      usersIds: data.usersInput?.split(',').map(Number),
      phone: data.phone,
      paymentTransactionId: data.paymentTransactionId,
      remoteTransactionId: data.remoteTransactionId,
      reason: data.reason,
      isAwaAffected,
      type: 'OUT',
      token: data.token,
    };

    if (requestBody.paymentTransactionId === '') {
      delete requestBody.paymentTransactionId;
    }
    if (requestBody.remoteTransactionId === '') {
      delete requestBody.remoteTransactionId;
    }
    mutation.mutate(requestBody);
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
  };

  const changeAwaEffect = () => {
    setIsAwaAffected(!isAwaAffected);
  };

  return (
    <Modal
      open={isOutBoModalOpen}
      width={400}
      footer={null}
      onCancel={() => setIsOutBoModalOPen(false)}
      title='OUT'>
      <Form
        form={form}
        validateTrigger='onSubmit'
        disabled={mutation.isLoading}
        onFinish={onFinish}>
        <Input.Group compact>
          {selectedOption === 'usersInput' ? (
            <Form.Item
              style={{ width: '58.5%' }}
              rules={[
                {
                  required: true,
                  message: 'The field is required!',
                },
                {
                  pattern: /^[\d,]+$/,
                  message: 'Input must include only chars and comma!',
                },
              ]}
              name='usersInput'>
              <Input placeholder='Player ID' />
            </Form.Item>
          ) : (
            <>
              <span style={{ marginTop: 5, marginRight: 2 }}>
                {phoneNumberCode}
              </span>
              <Form.Item
                style={{ width: '49%' }}
                rules={[
                  {
                    required: true,
                    message: 'The field is required!',
                  },
                  {
                    pattern: /^\d{6,}$/,
                    message:
                      'Input must include only numbers and be at least 6 characters long!',
                  },
                ]}
                name='phone'>
                <Input placeholder='Phone Number' />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Select
              defaultValue='Player ID'
              onChange={handleOptionChange}
              style={{ width: '140px' }}>
              {filtersForUserIdOrNumber.map(filter => (
                <Select.Option key={filter.value} value={filter.value}>
                  {filter.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Input.Group>

        <Row>
          <Col span={14}>
            <Form.Item name='amount'>
              <Input placeholder='Amount' type='number' />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name='opType'>
              <Select
                onChange={handleOpTypeChange}
                style={{ width: '9rem' }}
                options={filters}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              style={{
                display:
                  paymentTrxIdRequiredLevel === 'NONE' ? 'none' : 'block',
              }}
              name='paymentTransactionId'
              rules={[
                {
                  required: paymentTrxIdRequiredLevel === 'REQUIRED',
                  message: 'Payment TRX ID is required.',
                },
              ]}>
              <Input
                placeholder={
                  paymentTrxIdRequiredLevel === 'REQUIRED'
                    ? 'Payment TRX ID *'
                    : 'Payment TRX ID'
                }
              />
            </Form.Item>
            <Form.Item
              style={{
                display: reasonRequiredLevel === 'NONE' ? 'none' : 'block',
              }}
              name='reason'
              rules={[
                {
                  required: reasonRequiredLevel === 'REQUIRED',
                  message: 'Reason is required.',
                },
              ]}>
              <Input
                placeholder={
                  reasonRequiredLevel === 'REQUIRED' ? 'Reason *' : 'Reason'
                }
              />
            </Form.Item>
            {/* <Form.Item name='token'>
              <Input.Password
                prefix={<LockOutlined className='site-form-item-icon' />}
                placeholder='Secret Token '
              />
            </Form.Item> */}
            <Form.Item name='isAwaAffected'>
              <Checkbox value={isAwaAffected} onChange={changeAwaEffect}>
                AWA
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className={Classes.SaveAndCancelButtons}>
          <Button
            onClick={() => setIsOutBoModalOPen(false)}
            type='primary'
            danger>
            Cancel
          </Button>
          <Button loading={mutation.isLoading} type='primary' htmlType='submit'>
            Save
            <SaveOutlined />
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default OutBoModal;
