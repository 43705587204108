import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  getPlayerForMove,
  getPlayerTrxThunk,
  getTrxFiltersThunk,
} from './playerTrxThunk';

const initialState = {
  playerTrx: {
    data: {
      list: [],
      count: 0,
      deposit_amount: 0,
      total_deposit_amount: 0,
      total_withdraw_amount: 0,
      withdraw_amount: 0,
    },
    loading: false,
    error: null,
  },
  trxFilters: {
    op_types: {
      IN: [],
      OUT: [],
    },
    additionalOpTypes: [],
    loading: false,
    error: null,
  },
  moveUser: {
    loading: false,
    error: null as string | null,
  },
};

const playerTransactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    clearMoveUser(state) {
      state.moveUser = {
        loading: false,
        error: null,
      };
    },
    deleteTransaction(state, action) {
      const transactionId = action.payload;
      state.playerTrx.data.list = state.playerTrx.data.list.filter(
        (trx: any) => trx.id !== transactionId,
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPlayerTrxThunk.pending, state => {
        state.playerTrx.loading = true;
        state.playerTrx.error = null;
      })
      .addCase(getPlayerTrxThunk.fulfilled, (state, action) => {
        state.playerTrx.loading = false;
        state.playerTrx.data.list = action.payload.list;
        state.playerTrx.data.count = action.payload.count;
        state.playerTrx.data.deposit_amount = action.payload.deposit_amount;
        state.playerTrx.data.total_deposit_amount =
          action.payload.total_deposit_amount;
        state.playerTrx.data.total_withdraw_amount =
          action.payload.total_withdraw_amount;
        state.playerTrx.data.withdraw_amount = action.payload.withdraw_amount;
      })
      .addCase(getPlayerTrxThunk.rejected, state => {
        state.playerTrx.loading = false;
      })
      .addCase(getTrxFiltersThunk.pending, state => {
        state.trxFilters.loading = true;
        state.trxFilters.error = null;
      })
      .addCase(getTrxFiltersThunk.fulfilled, (state, action) => {
        state.trxFilters.loading = false;
        state.trxFilters.op_types = action.payload.op_types;
        state.trxFilters.additionalOpTypes = action.payload.additional_op_types;
      })
      .addCase(getTrxFiltersThunk.rejected, state => {
        state.trxFilters.loading = false;
      })

      .addCase(getPlayerForMove.pending, state => {
        state.moveUser.loading = true;
        state.moveUser.error = null;
      })
      .addCase(getPlayerForMove.fulfilled, (state, action) => {
        state.moveUser.loading = false;
        if (
          Array.isArray(action.payload.list) &&
          action.payload.list.length === 0
        ) {
          // Handle empty array case
          state.moveUser.error = 'User not found';
          toast.error('User not found', {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          state.moveUser = action.payload;
        }
      })
      .addCase(getPlayerForMove.rejected, state => {
        state.moveUser.loading = false;
        toast.error('User not found', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  },
});

export const { clearMoveUser, deleteTransaction } =
  playerTransactionSlice.actions;
export default playerTransactionSlice.reducer;
