/* eslint-disable */
import { useEffect, useState } from 'react';
import { Button, Col, Row, Table, DatePicker } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import dayjs, { Dayjs } from 'dayjs';

import { balLogSmallTableColumns } from '../../helpers/costants';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  exportFile,
  getBalanaceLogThunk,
  getSportCasinoGamesData,
} from 'redux/reducers/players/balanceLog/balanceLogThunk';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import scrollIntoView from 'scroll-into-view';
import { toast } from 'react-toastify';
import { setIsUpdate } from 'redux/reducers/players/balanceLog/balanceLogSlice';
import { formatNumberWithCommas } from 'components/Routes/Players/players.service';

interface BalanceProps {
  setCurrentPage: (page: any) => void;
  currentPage: any;
  setSelectedRowKey: any;
  pageSize: any;
}

const { RangePicker } = DatePicker;

const BalanceLogHeader: React.FC<BalanceProps> = ({
  setCurrentPage,
  currentPage,
  setSelectedRowKey,
  pageSize,
}) => {
  const { id } = useParams<{ id: any }>();
  const [localData, setLocalData] = useState<any>({
    date_from: dayjs().subtract(1, 'week').startOf('day'),
    date_to: dayjs().endOf('day').set('hour', 23).set('minute', 59),
  });
  const dispatch = useAppDispatch();
  const { sport, casino, games } = useSelector(
    (state: any) => state.balanceLogSlice.sportCasinoGames,
  );
  const { isUpdate } = useSelector((state: any) => state.balanceLogSlice.fix);
  const { lastDepositLog, lastWithdrawLog, list } = useSelector(
    (state: any) => state.balanceLogSlice.data,
  );
  const { loading } = useSelector((state: any) => state.balanceLogSlice);
  const [selected, setSelected] = useState();
  const activeTabKey = useSelector((state: any) => state.players.activeTabKey);

  const transformData = () => {
    return [
      {
        key: id,
        sport_total_bet: formatNumberWithCommas(
          sport?.total_by_date?.total_bet || 0,
        ),
        sport_total_won: formatNumberWithCommas(
          sport?.total_by_date?.total_won || 0,
        ),
        sport_ggr: formatNumberWithCommas(sport?.total_by_date?.ggr || 0),
        casino_total_bet: formatNumberWithCommas(
          casino?.total_by_date?.total_bet || 0,
        ),
        casino_total_won: formatNumberWithCommas(
          casino?.total_by_date?.total_won || 0,
        ),
        casino_ggr: formatNumberWithCommas(casino?.total_by_date?.ggr || 0),
        games_total_bet: formatNumberWithCommas(
          games?.total_by_date?.total_bet || 0,
        ),
        games_total_won: formatNumberWithCommas(
          games?.total_by_date?.total_won || 0,
        ),
        games_ggr: formatNumberWithCommas(games?.total_by_date?.ggr || 0),
      },
    ];
  };

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setLocalData({
        date_from: start
          ? dayjs(start.format())
          : dayjs().subtract(1, 'week').startOf('day'),
        date_to: end
          ? dayjs(end.format())
          : dayjs().endOf('day').set('hour', 23).set('minute', 59),
      });
    }
  };

  const handleRequest = () => {
    if (id) {
      const requestData = {
        player_id: parseInt(id),
        limit: pageSize,
        page: currentPage,
        ...localData,
      };
      setCurrentPage(1);
      dispatch(getBalanaceLogThunk(requestData));
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          ...localData,
          sections: ['sport', 'casino', 'games'],
        }),
      );
    }
    setSelectedRowKey(null);
  };

  const scrollToHighlightedRow = () => {
    const targetElement = document.querySelector('.scroll-row');
    if (targetElement instanceof HTMLElement) {
      scrollIntoView(targetElement, {
        align: {
          top: 0,
        },
      });
    }
  };

  const handleHighlightRow = (page: string, betId: any) => {
    setSelectedRowKey(null);
    setCurrentPage(page);
    dispatch(
      getBalanaceLogThunk({
        player_id: parseInt(id),
        limit: pageSize,
        page: page,
        ...localData,
      }),
    );
    setSelected(betId);
  };

  useEffect(() => {
    if (selected) {
      const index = list.findIndex((row: any) => row.id === selected);
      setSelectedRowKey(index);
      setTimeout(scrollToHighlightedRow, pageSize);
    }
  }, [list]);

  useEffect(() => {
    if (id) {
      const requestData = {
        player_id: parseInt(id),
        limit: pageSize,
        page: currentPage,
        ...localData,
      };
      dispatch(getBalanaceLogThunk(requestData));
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          ...localData,
          sections: ['sport', 'casino', 'games'],
        }),
      );
    }
    setSelectedRowKey(null);
  }, [currentPage, pageSize, activeTabKey]);

  useEffect(() => {
    if (isUpdate) {
      const requestData = {
        player_id: parseInt(id),
        limit: pageSize,
        page: currentPage,
        ...localData,
      };
      dispatch(getBalanaceLogThunk(requestData));
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          ...localData,
          sections: ['sport', 'casino', 'games'],
        }),
      );
    }
    dispatch(setIsUpdate(false));
  }, [isUpdate]);

  const exportEnv = () => {
    if (id) {
      const requestData = {
        player_id: parseInt(id),
        ...localData,
      };
      dispatch(exportFile(requestData));
    }
  };

  const handleLastDepositClick = () => {
    if (!lastDepositLog) {
      toast.error('In this range, no data for last deposit', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    handleHighlightRow(lastDepositLog.page, lastDepositLog.balance_log_id);
  };

  const handleLastWithdrawClick = () => {
    if (!lastWithdrawLog) {
      toast.error('In this range, no data for last withdraw', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    handleHighlightRow(lastWithdrawLog.page, lastWithdrawLog.balance_log_id);
  };

  return (
    <>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <FormItem label=''>
                <RangePicker
                  showTime
                  defaultValue={[localData.date_from, localData.date_to]}
                  // value={[localData.date_from, localData.date_to]}
                  onChange={handleRangeChange}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <Button
                onClick={handleRequest}
                style={{ marginLeft: '10px' }}
                type='primary'>
                Filter
              </Button>
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              span={6}>
              <Button onClick={exportEnv} type='primary'>
                Export
              </Button>
              <Button
                onClick={handleLastDepositClick}
                type='primary'
                style={{ marginLeft: '3px' }}>
                Last Deposit
              </Button>
              <Button
                onClick={handleLastWithdrawClick}
                type='primary'
                style={{ marginLeft: '3px' }}>
                Last Withdraw
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Col span={10}>
            {
              <Table
                loading={loading}
                bordered
                size='small'
                dataSource={transformData()}
                columns={balLogSmallTableColumns}
                pagination={false}
              />
            }
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default BalanceLogHeader;
