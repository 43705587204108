import { FC } from 'react';
import { Col, Row, Table } from 'antd';
import { useSelector } from 'react-redux';

import { formatNumberWithCommas } from 'components/Routes/Players/players.service';

import {
  sportSmallByDateTableColumn,
  sportSmallTotalTableColumn,
} from '../../helpers/costants';

const SportTabSmallTable: FC = () => {
  const { sport } = useSelector(
    (state: any) => state.balanceLogSlice.sportCasinoGames,
  );
  const { loading } = useSelector((state: any) => state.balanceLogSlice);

  const transformData = (byWhat: string) => {
    return [
      {
        key: '1',
        sport_total_bet: formatNumberWithCommas(
          sport?.[byWhat]?.total_bet || 0,
        ),
        sport_total_won: formatNumberWithCommas(
          sport?.[byWhat]?.total_won || 0,
        ),
        ggr: formatNumberWithCommas(sport?.[byWhat]?.ggr || 0),
      },
    ];
  };

  return (
    <Row>
      <Col span={6}>
        <Table
          dataSource={transformData('total')}
          columns={sportSmallTotalTableColumn}
          pagination={false}
          bordered
          size='middle'
          loading={loading}
        />
      </Col>
      <Col style={{ marginLeft: 5 }} span={6}>
        <Table
          dataSource={transformData('total_by_date')}
          columns={sportSmallByDateTableColumn}
          pagination={false}
          bordered
          size='middle'
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default SportTabSmallTable;
