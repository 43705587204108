import { combineReducers } from '@reduxjs/toolkit';

import projects from './projects/projects.slice';
import serverConfigs from './serverConfigs/serverConfigs.slice';
import appConfigs from './appConfigs/appConfigs.slice';
import notifications from './notifications/notifications.slice';
import transactionReducer from './transactions/transactions.slice';
import players from './players/playersSlice';
import playerDetails from './players/playerDetails/playerDetailsSlice';
import playerTrxSlice from './players/playerTrx/playerTrxSlice';
import balanceLogSlice from './players/balanceLog/balanceLogSlice';
import casinoSlice from './players/casino/casinoSlice';
import playerSportSlice from './players/sport/playerSportSlice';
import playerMessageSlice from './players/message/playerMessageSlice';

export const reducers = combineReducers({
  serverConfigs,
  appConfigs,
  notifications,
  projects,
  transaction: transactionReducer,
  players,
  playerDetails,
  playerTrxSlice,
  balanceLogSlice,
  casinoSlice,
  playerSportSlice,
  playerMessageSlice,
});
