import { createSlice } from '@reduxjs/toolkit';

import {
  getAggregatorsThunks,
  getCasinoBetsBySessionThunks,
  getPlayerCasinoData,
} from './casinoThunk';
import { BalanceLogState } from './types';

// Initial state
const initialState: BalanceLogState = {
  data: {
    playerCasinoData: [],
    count: 0,
    aggregators: [],
    session: [],
    sessionCount: 0,
  },
  loading: false,
  error: null,
};

const casinoLogSlice = createSlice({
  name: 'casino',
  initialState,
  reducers: {
    cleanSessons: state => {
      state.data.session = [];
      state.data.sessionCount = 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAggregatorsThunks.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAggregatorsThunks.fulfilled, (state, action) => {
        state.loading = false;
        state.data.aggregators = action.payload.list.map(
          (aggregator: string) => ({
            label: aggregator
              .toLowerCase()
              .replace(/_/g, ' ')
              .replace(/\b\w/g, char => char.toUpperCase()),
            value: aggregator.toLowerCase().replace(/\s+/g, '_'),
          }),
        );
        state.data.playerCasinoData = action.payload.list;
        state.data.count = action.payload.list.length;
      })
      .addCase(getAggregatorsThunks.rejected, state => {
        state.loading = false;
      })
      .addCase(getPlayerCasinoData.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlayerCasinoData.fulfilled, (state, action) => {
        state.loading = false;
        state.data.playerCasinoData = action.payload.list;
        state.data.count = action.payload.count;
      })
      .addCase(getPlayerCasinoData.rejected, state => {
        state.loading = false;
      })
      .addCase(getCasinoBetsBySessionThunks.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCasinoBetsBySessionThunks.fulfilled, (state, action) => {
        state.loading = false;
        state.data.session = action.payload.list;
        state.data.sessionCount = action.payload.count;
      })
      .addCase(getCasinoBetsBySessionThunks.rejected, state => {
        state.loading = false;
      });
  },
});

export const { cleanSessons } = casinoLogSlice.actions;
export default casinoLogSlice.reducer;
