/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import FormItem from 'antd/es/form/FormItem';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  getAggregatorsThunks,
  getPlayerCasinoData,
} from 'redux/reducers/players/casino/casinoThunk';

import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { casinoMoneyOption } from '../../helpers/costants';
import { getSportCasinoGamesData } from 'redux/reducers/players/balanceLog/balanceLogThunk';

const { RangePicker } = DatePicker;

interface ILocalData {
  aggregators: any;
  currentPage: any;
  pageSize: any;
  onDateChange: (dates: [Dayjs | null, Dayjs | null] | null) => void;
}

const CasinoTabFilter: FC<ILocalData> = ({
  aggregators,
  currentPage,
  pageSize,
  onDateChange,
}) => {
  const { id } = useParams<{ id: string }>();

  const [localData, setLocalData] = useState<any>({
    date_from: dayjs().subtract(1, 'week').startOf('day'),
    date_to: dayjs().endOf('day').set('hour', 23).set('minute', 59),
    aggregators: [],
    money_options: casinoMoneyOption.map(option => option.value),
  });
  const dispatch = useAppDispatch();
  const { loading } = useSelector((state: any) => state.casinoSlice);

  useEffect(() => {
    const allAggregatorsValues = aggregators.map((agg: any) => agg.value);
    setLocalData((prevState: any) => ({
      ...prevState,
      aggregators: allAggregatorsValues,
    }));
    const hasFilters = allAggregatorsValues.length > 0;
    hasFilters &&
      dispatch(
        getPlayerCasinoData({
          limit: pageSize,
          page: currentPage,
          filters: {
            ...localData,
            aggregators: allAggregatorsValues,
            player_id: id,
          },
        }),
      );
  }, [aggregators, currentPage, pageSize]);

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates) {
      const [start, end] = dates;
      setLocalData((prevState: any) => ({
        ...prevState,
        date_from: start
          ? dayjs(start.format())
          : dayjs().subtract(1, 'week').startOf('day'),
        date_to: end
          ? dayjs(end.format())
          : dayjs().endOf('day').set('hour', 23).set('minute', 59),
      }));
      onDateChange(dates);
    }
  };

  const handleOptionChange = (key: string, value: string | string[]) => {
    if (key === 'supplier') {
      const selectedOptions = Array.isArray(value) ? value : [value];
      if (selectedOptions.includes('all')) {
        const allAggregatorsValues = aggregators.map((agg: any) => agg.value);
        setLocalData((prevState: any) => ({
          ...prevState,
          aggregators: allAggregatorsValues,
        }));
      } else {
        setLocalData((prevState: any) => ({
          ...prevState,
          aggregators: selectedOptions as any,
        }));
      }
    }
  };

  const onSearch = () => {
    dispatch(
      getPlayerCasinoData({
        limit: pageSize,
        page: currentPage,
        filters: {
          ...localData,
          player_id: id,
        },
      }),
    );

    const { date_to, date_from } = localData;
    if (id) {
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          date_to,
          date_from,
          sections: ['casino'],
        }),
      );
    }
  };

  useEffect(() => {
    const { date_to, date_from } = localData;
    if (id) {
      dispatch(
        getSportCasinoGamesData({
          player_id: parseInt(id),
          date_to,
          date_from,
          sections: ['casino'],
        }),
      );
    }
  }, []);

  const handleOption = (key: string, value: string | string[]) => {
    if (key === 'money_options') {
      const selectedOptions = Array.isArray(value) ? value : [value];
      if (selectedOptions.includes('all')) {
        const allCasinoMoneyOptionValues = casinoMoneyOption.map(
          (agg: any) => agg.value,
        );
        setLocalData((prevState: any) => ({
          ...prevState,
          money_options: allCasinoMoneyOptionValues,
        }));
      } else {
        setLocalData((prevState: any) => ({
          ...prevState,
          money_options: selectedOptions as any,
        }));
      }
    }
  };

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Form layout='vertical'>
          <Row>
            <Col span={5}>
              <FormItem label='Select Range'>
                <RangePicker
                  showTime
                  defaultValue={[localData.date_from, localData.date_to]}
                  // value={[localData.date_from, localData.date_to]}
                  onChange={handleRangeChange}
                />
              </FormItem>
            </Col>
            <Col span={3} offset={1}>
              <FormItem label='Money type'>
                <Select
                  maxTagCount={1}
                  mode='multiple'
                  style={{ width: '100%' }}
                  value={localData.money_options}
                  onChange={(value: string | string[]) =>
                    handleOption('money_options', value)
                  }
                  options={[
                    { label: 'All', value: 'all' },
                    ...casinoMoneyOption,
                  ]}
                />
              </FormItem>
            </Col>
            <Col span={6} offset={1}>
              <FormItem label='Supplier'>
                <Select
                  maxTagCount={1}
                  mode='multiple'
                  style={{ width: '100%' }}
                  placeholder='Select supplier options'
                  value={localData.aggregators}
                  onChange={(value: string | string[]) =>
                    handleOptionChange('supplier', value)
                  }
                  options={[{ label: 'All', value: 'all' }, ...aggregators]}
                />
              </FormItem>
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              span={7}>
              <FormItem label='&nbsp;'>
                <Button onClick={onSearch} type='primary'>
                  Search
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default CasinoTabFilter;
