import { Link } from 'react-router-dom';

export const playerTableColumns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    render: (id: number) => <Link to={`/players/${id}`}>{id}</Link>,
  },
  {
    title: 'Name',
    dataIndex: 'f_name',
    key: 'f_name',
    render: (f_name: string) => f_name || '-',
  },
  // {
  //   title: 'Lastname',
  //   dataIndex: 'l_name',
  //   key: 'l_name',
  //   render: (l_name: string) => l_name || '-',
  // },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    render: (username: string) => username || '-',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (phone: string) => phone || '-',
  },
  {
    title: 'Email',
    dataIndex: 'e_mail',
    key: 'e_mail',
    render: (e_mail: string) => e_mail || '-',
  },
  {
    title: 'Birthdate',
    dataIndex: 'birthday',
    key: 'birthday',
    render: (dateString: string) => {
      const dateObject = new Date(dateString);
      return dateObject.toLocaleDateString() || '-';
    },
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (balance: string) => balance || '-',
  },
];
