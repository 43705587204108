/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { FormData } from './types';

export const convertFormData = (formData: FormData) => {
  const convertedData = {
    user_id: parseInt(formData.user_id) || undefined,
    phone: formData.phone || undefined,
    birthdayFrom: formData.birthdayFrom
      ? new Date(formData.birthdayFrom)
      : undefined,
    birthdayTo: formData.birthdayTo ? new Date(formData.birthdayTo) : undefined,
    f_name: formData.f_name || undefined,
    l_name: formData.l_name || undefined,
    username: formData.username || undefined,
    e_mail: formData.e_mail || undefined,
    balanceFrom: parseInt(formData.balanceFrom) || undefined,
    balanceTo: parseInt(formData.balanceTo) || undefined,
    verification_id: formData.verification_id || undefined,
    referer: parseInt(formData.referer) || undefined,
    limit: formData.limit === null || formData.limit,
    page: formData.page === null || formData.page,
    type: formData.type,
  };

  return convertedData;
};

export const getPlayersThunk = createAsyncThunk(
  'players/getPlayers',
  async (formData: any, { rejectWithValue }) => {
    try {
      const convertedData = convertFormData(formData);
      const response = await axios.post('/player/list', convertedData);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.log(rejectWithValue(error.response.data), 'no');

        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: 'An unknown error occurred',
        error_code: -1,
      });
    }
  },
);

/* eslint-enable */
