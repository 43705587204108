import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { getPlayersThunk } from './playersThunk';
import { PlayersState } from './types';

const initialState: PlayersState = {
  data: [],
  count: 0,
  activeTabKey: '1',
  choosenBetCasinoId: null,
  choosenBetSportData: { logId: null, date: null },
  loading: false,
  error: null,
};

const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    clearData(state) {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
    setActiveTabKey(state, action) {
      state.activeTabKey = action.payload;
    },
    setChoosenCasinoBetId(state, action) {
      state.choosenBetCasinoId = action.payload;
    },
    setChoosenSportBetData(state, action) {
      state.choosenBetSportData = {
        logId: action.payload.logId,
        date: action.payload.date,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPlayersThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlayersThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.list;
        state.count = action.payload.count;
      })
      .addCase(getPlayersThunk.rejected, (state, action) => {
        state.loading = false;
        if (action.payload && typeof action.payload === 'object') {
          console.log('ok');

          const errorPayload = action.payload as any;
          state.error = errorPayload;
          toast.error(errorPayload.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  },
});

export const {
  clearData,
  setActiveTabKey,
  setChoosenCasinoBetId,
  setChoosenSportBetData,
} = playersSlice.actions;

export default playersSlice.reducer;
