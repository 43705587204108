import { createSlice } from '@reduxjs/toolkit';

import { getPlayerSportThunk } from './playerSportThunk';

const initialState = {
  sport: {
    list: [],
    count: '',
  },
  loading: false,
  error: null,
};

const playerSportSlice = createSlice({
  name: 'sport',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPlayerSportThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlayerSportThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.sport.list = action.payload.list;
        state.sport.count = action.payload.count;
      })
      .addCase(getPlayerSportThunk.rejected, state => {
        state.loading = false;
      });
  },
});

export default playerSportSlice.reducer;
