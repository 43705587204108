/* eslint-disable */

import { Col, Input, Modal, Row, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/hooks/redux.hooks';

import { infoModalcolumns } from './constant';
import { getCasinoBetsBySessionThunks } from 'redux/reducers/players/casino/casinoThunk';

import classes from './../../CasinoTab.module.scss';
import scrollIntoView from 'scroll-into-view';

interface Proptype {
  handleModalClose: () => void;
  isModalVisible: boolean;
  filters: any;
  selectedBet: any;
}
const SessionModal: FC<Proptype> = ({
  handleModalClose,
  isModalVisible,
  filters,
  selectedBet,
}) => {
  const { loading } = useSelector((state: any) => state.casinoSlice);
  const { session, sessionCount } = useSelector(
    (state: any) => state.casinoSlice.data,
  );
  const [selected, setSelected] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useAppDispatch();

  useEffect(() => {
    filters.id &&
      dispatch(
        getCasinoBetsBySessionThunks({
          limit: 50,
          page: currentPage,
          filters: {
            date_from: filters?.date_from,
            date_to: filters?.date_to,
            player_id: filters?.id,
            session: filters?.record,
            find_by_key: 'session',
            bet_id: null,
          },
        }),
      );
  }, [currentPage, selectedBet]);

  const scrollToHighlightedRow = () => {
    const targetElement = document.querySelector('.scroll-row');
    if (targetElement instanceof HTMLElement) {
      scrollIntoView(targetElement, {
        align: {
          top: 0,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedBet) {
      const index = session.findIndex((row: any) => row.id == selectedBet);
      setSelected(index);
      setTimeout(scrollToHighlightedRow);
    }
  }, [session]);

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
  };

  return (
    <Modal
      width={1200}
      visible={isModalVisible}
      onCancel={handleModalClose}
      footer={null}
      onOk={handleModalClose}>
      <Row>
        <Col span={24}>
          <Table
            rowClassName={(record, index) =>
              index === selected ? `scroll-row ${classes.scrollRow}` : ''
            }
            scroll={{ y: 240 }}
            bordered
            style={{ padding: '1rem' }}
            size='middle'
            columns={infoModalcolumns}
            dataSource={session}
            loading={loading}
            onChange={handleTableChange}
            pagination={{
              current: currentPage,
              total: sessionCount,
              pageSize: 50,
            }}
          />
        </Col>
        <Col offset={10} span={4}>
          <Input disabled addonBefore='Total Odds' value={sessionCount} />
        </Col>
      </Row>
    </Modal>
  );
};

export default SessionModal;
