import { Col, Input, Modal, Row, Table } from 'antd';
import { FC } from 'react';

import { infoModalcolumns } from './costants';

interface Proptype {
  handleModalClose: () => void;
  isModalVisible: boolean;
  bets: any;
  totalOdds: any;
  loading: boolean;
}
const SpTabGenTableModal: FC<Proptype> = ({
  handleModalClose,
  isModalVisible,
  bets,
  totalOdds,
  loading,
}) => {
  return (
    <Modal
      width={1200}
      visible={isModalVisible}
      onCancel={handleModalClose}
      footer={null}
      onOk={handleModalClose}>
      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            bordered
            scroll={{ x: 1000, y: 600 }}
            style={{ padding: '1rem' }}
            size='middle'
            columns={infoModalcolumns}
            dataSource={bets}
            pagination={false}
          />
        </Col>
        <Col offset={10} span={4}>
          <Input disabled addonBefore='Total Odds' value={totalOdds} />
        </Col>
      </Row>
    </Modal>
  );
};

export default SpTabGenTableModal;
