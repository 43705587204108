import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import classes from '../MessagesTabColumns.module.scss';

export const messagesTabColumns = [
  { title: 'ID', dataIndex: 'id', key: 'id', width: 80 },
  { title: 'MID', dataIndex: 'message_id', key: 'message_id', width: 100 },
  {
    title: 'TITLE',
    dataIndex: ['message', 'message_title'],
    key: 'message_title',
    width: 200,
    render: (text: string) => (
      <Tooltip title={text}>
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            maxWidth: '100%',
          }}>
          {text}
        </span>
      </Tooltip>
    ),
  },
  {
    title: 'MESSAGE',
    dataIndex: ['message', 'message_body'],
    key: 'message_body',
    width: 250,
    render: (text: string) => (
      <Tooltip title={text}>
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            maxWidth: '100%',
          }}>
          {text}
        </span>
      </Tooltip>
    ),
  },
  {
    title: 'SENDER',
    dataIndex: ['message', 'author'],
    key: 'author',
    width: 120,
  },
  {
    title: 'SOURCE',
    dataIndex: ['message', 'source'],
    key: 'source',
    width: 120,
  },
  {
    title: 'RECEIVED',
    dataIndex: 'inserted_at',
    key: 'inserted_at',
    width: 150,
    render: (text: string) => dayjs(text).utc().format('DD-MM-YYYY HH:mm'),
  },
  {
    title: 'SEEN',
    dataIndex: 'status',
    key: 'status',
    width: 50,
    render: (text: string) => (
      <>
        {text === 'READ' ? (
          <span className={classes.messagesTabCheckOutlined}>
            <CheckOutlined />
          </span>
        ) : (
          <span className={classes.messagesTabCloseOutlined}>
            <CloseOutlined />
          </span>
        )}
      </>
    ),
  },
];
