import { FC, useEffect } from 'react';
import { Button, Card, Col, Empty, Form, Row, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { getPlayerDetailsThunk } from 'redux/reducers/players/playerDetails/playerDetailsThunk';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { setActiveTabKey } from 'redux/reducers/players/playersSlice';

import { formatNumberWithCommas } from '../players.service';

import classes from './PlayersInfo.module.scss';
import { playerInfoTabsData } from './helpers/constans';
import PlayerInfoTab from './components/PlayerInfotab/PlayerInfoTab';

const PlayerInfo: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useSelector((state: any) => state.playerDetails);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dateObject = new Date(data?.last_visit_at);
  const regDate = new Date(data?.created);
  const { activeProjectId } = useSelector((state: any) => state.projects);
  const activeTabKey = useSelector((state: any) => state.players.activeTabKey);

  useEffect(() => {
    dispatch(getPlayerDetailsThunk(id));
  }, [id, activeProjectId]);

  const handleGoBack = () => {
    navigate('/players/');
  };

  const handleTabChange = (key: string) => {
    dispatch(setActiveTabKey(key));
  };

  const tabs = [
    {
      key: '1',
      label: 'PLAYER INFO',
      value: <PlayerInfoTab loading={loading} playerInfoData={data} />,
      disabled: false,
    },
    ...playerInfoTabsData,
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Card title='Player Info' style={{ height: '100%' }}>
          <Row className={classes.playerInfoSize}>
            <Col span={3}>
              <Form.Item
                label={<p style={{ fontSize: '17px' }}>PLAYER ID</p>}
                name='id'>
                {/* <span> &#9734;</span> */}
                <span className={classes.playerInfoSize}>{data?.id}</span>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={<p style={{ fontSize: '17px' }}>BALANCE</p>}
                name='balance'>
                <span className={classes.playerInfoSize}>
                  {formatNumberWithCommas(data?.balance)}
                </span>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={<p style={{ fontSize: '17px' }}>GGR</p>}
                name='ggr'>
                <span className={classes.playerInfoSize}>
                  {formatNumberWithCommas(data?.ggr)}
                </span>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={<p style={{ fontSize: '17px' }}>LAST VISIT</p>}
                name='lastVisit'>
                <span className={classes.playerInfoSize}>
                  {dateObject?.toLocaleDateString()}
                </span>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={<p style={{ fontSize: '17px' }}>REG DATE</p>}
                name='registrationAt'>
                <span className={classes.playerInfoSize}>
                  {regDate?.toLocaleDateString()}
                </span>
              </Form.Item>
            </Col>
            <Col>
              <Button style={{ width: 150 }} onClick={handleGoBack}>
                Go Back
              </Button>
            </Col>
          </Row>
          {data ? (
            <div className={classes.playerInfoBody}>
              <Tabs
                activeKey={activeTabKey}
                onChange={handleTabChange}
                style={{ width: '100%' }}
                size='small'
                type='card'
                items={tabs.map(({ key, label, value, disabled }) => {
                  return {
                    label,
                    disabled,
                    key,
                    children: value,
                  };
                })}
              />
            </div>
          ) : (
            <Empty style={{ marginTop: 100 }} description='No Data Available' />
          )}
        </Card>
      )}
    </>
  );
};

export default PlayerInfo;
