import { InputField } from './Players.types';

export const inputFields: InputField[] = [
  { name: 'user_id', placeholder: 'Player ID' },
  { name: 'phone', placeholder: 'Phone Number' },
  // { name: 'birthdayFrom', placeholder: 'Birthdate From' },
  // { name: 'birthdayTo', placeholder: 'Birthdate To' },
  { name: 'f_name', placeholder: 'Name' },
  { name: 'l_name', placeholder: 'Surname' },
  { name: 'username', placeholder: 'Username' },
  { name: 'e_mail', placeholder: 'Email' },
  { name: 'balanceFrom', placeholder: 'Balance From' },
  { name: 'balanceTo', placeholder: 'Balance To' },
  { name: 'verification_id', placeholder: 'Verification ID' },
  { name: 'referer', placeholder: 'Referrer' },
];
