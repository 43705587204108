/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import i18n from 'assets/translations';
import { RootState } from 'redux/store.types';
import { IProjectInfo } from 'services/projects';

import {
  IProjectsSlice,
  ISelectManualCheckPayload,
  TProjectId,
} from './projects.types';
import {
  getChooseProjectThunck,
  getGeneralLimitsThunk,
  getProjectInfo,
  getProjectsThunk,
  setGeneralLimitsThunk,
} from './projects.thunks';

const initialState: IProjectsSlice = {
  app_types: [],
  additional_op_types: [],
  countries: [],
  project: '',
  project_db: '',
  activeProjectId: null,
  generalLimits: {
    casino_ggr_limit: 0,
    casino_winning_limit: 0,
    daily_withdraw_limit: 0,
    games_ggr_limit: 0,
    games_winning_limit: 0,
    sport_ggr_limit: 0,
    sport_winning_limit: 0,
    used_unused_percentage: 0,
    rollback_limit_percentage: 0,
    tft: '',
  },
  isLoading: false,
  isGeneralLimitsLoading: false,
  projectPhoneCountryCode: '',
  manualCheckExist: 0,
};

export const projectsSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setPhoneNumberCode: (state, action: PayloadAction<string>) => {
      state.projectPhoneCountryCode = action.payload;
    },
    selectCountry: (state, action: PayloadAction<TProjectId>) => {
      state.activeProjectId = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateProjectInfo: (state, action: PayloadAction<IProjectInfo>) => {
      state.app_types = action.payload.app_types;
      state.additional_op_types = action.payload.additional_trx_types;
    },
    resetProjectsSlice: () => initialState,
  },

  extraReducers: builder => {
    builder
      .addCase(getProjectsThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(getProjectsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.countries = action.payload;
      })
      .addCase(getProjectsThunk.rejected, state => {
        state.isLoading = false;
        toast.error(i18n.t('Something went wrong'), {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .addCase(getChooseProjectThunck.pending, state => {
        state.isLoading = true;
      })
      .addCase(getProjectInfo.fulfilled, (state, action) => {
        state.manualCheckExist = action.payload.manual_check_exist;
      })
      .addCase(getChooseProjectThunck.fulfilled, (state, action) => {
        state.isLoading = false;
        state.project = action.payload.project;
        state.activeProjectId = action.payload.id;
      })
      .addCase(getChooseProjectThunck.rejected, state => {
        state.isLoading = false;
        toast.error(i18n.t('Something went wrong'), {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .addCase(getGeneralLimitsThunk.pending, state => {
        state.isGeneralLimitsLoading = true;
      })
      .addCase(getGeneralLimitsThunk.fulfilled, (state, action) => {
        state.isGeneralLimitsLoading = false;
        state.generalLimits = action.payload;
      })
      .addCase(getGeneralLimitsThunk.rejected, (state, { payload }) => {
        state.isGeneralLimitsLoading = false;
        toast.error(payload?.message || i18n.t('Something went wrong'), {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .addCase(setGeneralLimitsThunk.pending, state => {
        state.isGeneralLimitsLoading = true;
      })
      .addCase(setGeneralLimitsThunk.fulfilled, state => {
        state.isGeneralLimitsLoading = false;
        toast.success('Your changes successfully completed', {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .addCase(setGeneralLimitsThunk.rejected, (state, { payload }) => {
        state.isGeneralLimitsLoading = false;
        toast.error(payload?.message || i18n.t('Something went wrong'), {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  },
});

// ACTIONS
export const {
  selectCountry,
  setIsLoading,
  resetProjectsSlice,
  updateProjectInfo,
  setPhoneNumberCode,
} = projectsSlice.actions;

// SELECTORS
export const selectPhoneNumberCode = (state: RootState) =>
  state.projects.projectPhoneCountryCode;
export const selectCountries = (state: RootState) => state.projects.countries;
export const selectAppTypes = (state: RootState) => state.projects.app_types;
export const selectAdditionalAppTypes = (state: RootState) =>
  state.projects.additional_op_types;
export const selectActiveProjectID = (state: RootState) =>
  state.projects.activeProjectId;
export const selectIsCountryChoosen = (state: RootState) =>
  !!state.projects.activeProjectId;
export const selectIsGeneralLimitsLoading = (state: RootState) =>
  state.projects.isGeneralLimitsLoading;
export const selectGeneralLimits = (state: RootState) =>
  state.projects.generalLimits;
export const selectManualCheck = (state: RootState) =>
  state.projects.manualCheckExist;

export default projectsSlice.reducer;
