/* eslint-disable */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { setError, setIsUpdate } from './balanceLogSlice';
import { log } from 'console';

export const getBalanaceLogThunk = createAsyncThunk(
  'balanceLog/getBalanaceLogThunk',
  async (data: any) => {
    const response = await axios.post('/player/list-balance-logs', { ...data });
    return response.data;
  },
);

export const getSportCasinoGamesData = createAsyncThunk(
  'balanceLog/getSportCasinoGamesData',
  async (data: any) => {
    const response = await axios.post('/player/list-bet-statistics', {
      ...data,
    });
    return response.data;
  },
);

export const exportFile = createAsyncThunk(
  'balanceLog/export',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/player/export-balance-logs-csv',
        {
          ...data,
        },
        {
          responseType: 'blob', // Ensure the response is a blob
        },
      );

      if (response.status === 200) {
        // Create a URL for the blob
        const url = URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'balance_logs.csv'; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        a.remove();

        toast.success('Export successful', {
          position: toast.POSITION.TOP_CENTER,
        });
        return response.data;
      }
    } catch (error: any) {
      toast.error('Export failed', {
        position: toast.POSITION.TOP_CENTER,
      });
      return rejectWithValue(error.message);
    }
  },
);

export const fixBalanaceLogThunk = createAsyncThunk(
  'balanceLog/fixBalanaceLogThunk',
  async (data: any, { dispatch }) => {
    try {
      const response = await axios.post(
        '/player/undo-balance-log-operation',
        data,
      );
      if (response.status === 200) {
        dispatch(setIsUpdate(true));
        toast.success('Fixed successful', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      return response.data;
    } catch (error: any) {
      dispatch(setError(error.response.data));
      throw error;
    }
  },
);
