/* eslint-disable */
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import Search from 'antd/es/input/Search';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import classes from './TRXModal.module.scss';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  getPlayerForMove,
  moveTrx,
} from 'redux/reducers/players/playerTrx/playerTrxThunk';
import {
  clearMoveUser,
  deleteTransaction,
} from 'redux/reducers/players/playerTrx/playerTrxSlice';
import ModalUserData from './ModalUserData';
import { toast } from 'react-toastify';

import i18n from 'assets/translations';
import { getPlayerDetailsThunk } from 'redux/reducers/players/playerDetails/playerDetailsThunk';
import { useParams } from 'react-router-dom';

interface TRXModalProps {
  record: any;
}

const TRXModal: React.FC<TRXModalProps> = ({ record }) => {
  const { id } = useParams<{ id: string }>();
  const [trxModalIsOpen, setTrxModalIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  const { data } = useSelector((state: any) => state.playerDetails);
  const { list } = useSelector(
    (state: any) => state.playerTrxSlice.playerTrx.data,
  );
  const { moveUser } = useSelector((state: any) => state.playerTrxSlice);
  const [currentTrx, setCurrentTrx] = useState<any>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const foundTransaction = list.find((item: any) => item.id === record.id);
    setCurrentTrx(foundTransaction);
  }, [list, record]);

  useEffect(() => {
    searchValue === '' && dispatch(clearMoveUser());
  }, [searchValue]);

  const onSearch = (value: string) => {
    dispatch(getPlayerForMove(value));
    setSearchValue(value);
  };

  const handleModalClose = () => {
    setTrxModalIsOpen(false);
    setSearchValue('');
    dispatch(clearMoveUser());
  };

  const handleModalMove = () => {
    !searchValue &&
      toast.error('Please choose user to move', {
        position: toast.POSITION.TOP_CENTER,
      });

    if (moveUser?.list[0]) {
      dispatch(moveTrx({ trxId: currentTrx.id, userId: searchValue }))
        .unwrap()
        .then(data => {
          setTrxModalIsOpen(false);
          setSearchValue('');
          dispatch(deleteTransaction(currentTrx.id));
          dispatch(clearMoveUser());
          dispatch(getPlayerDetailsThunk(id));
        })
        .catch(error => {
          toast.error(i18n.t('Something went wrong'), {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <>
      <Button type='primary' onClick={() => setTrxModalIsOpen(true)}>
        {t('Move')}
      </Button>
      <Modal
        open={trxModalIsOpen}
        onOk={handleModalMove}
        onCancel={handleModalClose}
        okText={t('Move')}
        width={1000}>
        {currentTrx ? (
          <Row>
            <ModalUserData
              newUser={false}
              data={data}
              currentTrx={currentTrx}
            />
            <div className={classes.verticalDivider}></div>
            <Col span={13} className={classes.modalCol}>
              <Search
                onSearch={onSearch}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                placeholder={t('Search Player')}
                size='large'
              />
              {moveUser?.list?.length > 0 && (
                <div className={classes.movedUserInfo}>
                  <ModalUserData
                    newUser={true}
                    data={moveUser?.list[0]}
                    currentTrx={currentTrx}
                  />
                </div>
              )}
            </Col>
          </Row>
        ) : null}
      </Modal>
    </>
  );
};

export default TRXModal;
