import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { shortTransactionData } from './transaction.thunk';

interface TransactionState {
  list: any[];
  count: number;
  amount: string;
  users_count: number;
  loading: boolean;
  error: string | null;
}

const initialState: TransactionState = {
  list: [],
  count: 0,
  amount: '',
  users_count: 0,
  loading: false,
  error: null,
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(shortTransactionData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        shortTransactionData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.list = action.payload.list;
          state.count = action.payload.count;
          state.amount = action.payload.amount;
          state.users_count = action.payload.users_count;
        },
      )
      .addCase(shortTransactionData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.toString()
          : 'An error occurred';
      });
  },
});

export const { updateList } = transactionSlice.actions;

export default transactionSlice.reducer;
