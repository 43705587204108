/* eslint-disable */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

import { addNote, deleteNote } from './playerDetailsSlice';
import {
  ICreateNote,
  IDeleteNote,
  IUpdatePlayerDetailsReq,
} from './playerDetails.types';

export const getPlayerDetailsThunk = createAsyncThunk(
  'playerDetails/getPlayerDetailsThunk',
  async (id: any) => {
    const response = await axios.post('/player/details', { id });
    return response.data;
  },
);

export const updatePlayerDetailsThunk = createAsyncThunk(
  'playerDetails/updatePlayerDetailsThunk',
  async (updatedData: any, { rejectWithValue }) => {
    const { playerId, data } = updatedData;

    const transformedData = {
      data: data,
      player_id: playerId,
    };

    try {
      const response = await axios.post(
        '/player/update-details',
        transformedData,
      );

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.log(rejectWithValue(error.response.data), 'no');

        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: 'An unknown error occurred',
        error_code: -1,
      });
    }
  },
);

export const createNoteThunk = createAsyncThunk<void, ICreateNote>(
  'playerDetails/createNote',
  async ({ playerId, note, noteType }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/player/add-note', {
        player_id: playerId,
        note,
        noteType,
      });
      if (response.status === 200) {
        dispatch(addNote(response.data));
        toast.success('Note Add successfully', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        throw new Error('Failed to Add note');
      }
    } catch (error) {
      toast.error('Failed to Add note', {
        position: toast.POSITION.TOP_CENTER,
      });
      return rejectWithValue('Failed to Add note');
    }
  },
);

export const deleteNoteThunk = createAsyncThunk<void, IDeleteNote>(
  'playerDetails/deleteNote',
  async ({ playerId, noteId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/player/delete-note', {
        player_id: playerId,
        note_id: noteId,
      });

      if (response.status === 200) {
        dispatch(deleteNote(noteId));
        toast.success('Note deleted successfully', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        throw new Error('Failed to delete note');
      }
    } catch (error) {
      toast.error('Failed to delete note', {
        position: toast.POSITION.TOP_CENTER,
      });
      return rejectWithValue('Failed to delete note');
    }
  },
);
