import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getPlayerSportThunk = createAsyncThunk(
  'sport/getPlayerSportThunk',
  async (data: any) => {
    const transformedData = {
      ...data,
      filters: {
        ...data.filters,
        status: data.filters.status.map((value: string) => parseInt(value, 10)),
        ztype: data.filters.ztype.map((value: string) => parseInt(value, 10)),
      },
    };
    console.log(transformedData.filters);

    const response = await axios.post(
      '/player/list-player-sport-bets',
      transformedData,
    );
    return response.data;
  },
);
