/* eslint-disable */
import { ReactNode } from 'react';

import BalanceLogTab from '../components/BalanceLogTab/BalanceLogTab';
import MessageTab from '../components/MessagesTab/MessagesTab';
import SettingsTab from '../components/Settings.tsx/SettingsTab';
import TrxTab from '../components/TRXTab/TrxTab';
import BonusTab from '../components/BonusTab/BonusTab';
import SportTab from '../components/SportTab/SportTab';
import CasinoTab from '../components/CasinoTab/CasinoTab';
import GamesTab from '../components/GamesTab/GamesTab';

interface SegmentedOptions {
  key: string;
  label: string;
  value: ReactNode;
  disabled: boolean;
  props?: object;
}

export const playerInfoTabsData: SegmentedOptions[] = [
  {
    key: '2',
    label: 'TRX',
    value: <TrxTab />,
    disabled: false,
  },
  {
    key: '3',
    label: 'BALANCE LOG',
    value: <BalanceLogTab />,
    disabled: false,
  },
  {
    key: '4',
    label: 'SPORT',
    value: <SportTab />,
    disabled: false,
  },
  {
    key: '5',
    label: 'CASINO',
    value: <CasinoTab />,
    disabled: false,
  },
  // {
  //   key: '6',
  //   label: 'GAMES',
  //   value: <GamesTab />,
  //   disabled: false,
  // },
  {
    key: '7',
    label: 'MESSAGES',
    value: <MessageTab />,
    disabled: false,
  },
  // {
  //   key: '8',
  //   label: 'BONUS',
  //   value: <BonusTab />,
  //   disabled: false,
  // },
  // {
  //   key: '9',
  //   label: 'SETTINGS',
  //   value: <SettingsTab />,
  //   disabled: false,
  // },
];

interface TrxTypeOption {
  title: string;
  value: string;
  children: {
    title: string;
    value: string;
  }[];
}

export const transformTrxFilters = (filters: any): TrxTypeOption[] => {
  const transformOpType = (type: string, items: any[]): TrxTypeOption => ({
    title: type,
    value: type,
    children: items.map(item => ({
      title: item.name,
      value: item.name,
    })),
  });

  const opTypes = filters.op_types;
  return Object.keys(opTypes).map(type => transformOpType(type, opTypes[type]));
};

export function transformChildren(
  title: string,
  value: string,
  childArray: string[],
): TrxTypeOption {
  return {
    title: title,
    value: value,
    children: childArray.map(child => ({
      title: child,
      value: child,
    })),
  };
}
