/* eslint-disable */

import { useState, FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button, Table, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  deleteMessageThunk,
  getPlayerMessageThunk,
} from 'redux/reducers/players/message/playerMessageThunk';

import { IMessagesTabData } from './helpers/MessagesTab.types';
import { messagesTabColumns } from './helpers/constants';
import classes from './MessagesTabColumns.module.scss';
import MessageModal from './MessageModal';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const TableComponent: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const dispatch = useAppDispatch();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState<IMessagesTabData | null>(
    null,
  );
  const { message, loading } = useSelector(
    (state: any) => state.playerMessageSlice,
  );

  const handleEdit = (record: IMessagesTabData) => {
    setEditingRecord(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = (record: any) => {
    dispatch(deleteMessageThunk(record.message_id));
  };

  const handleNewMessage = () => {
    setEditingRecord(null);
    setIsEditModalVisible(true);
  };

  const handleModalClose = () => {
    setIsEditModalVisible(false);
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleEditFormSubmit = (values: IMessagesTabData) => {
    setIsEditModalVisible(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(
        getPlayerMessageThunk({
          player_id: parseInt(id),
          limit: 50,
          page: currentPage,
        }),
      );
    }
  }, [currentPage, pageSize]);

  const tableColumnsWithAction = [
    ...messagesTabColumns,
    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_: any, record: IMessagesTabData) => (
        <div>
          <Tooltip title={t('Edit')}>
            <span
              className={classes.messagesTabEditButton}
              onClick={() => handleEdit(record)}>
              <EditOutlined style={{ cursor: 'pointer' }} />
            </span>
          </Tooltip>
          <Tooltip title={t('Delete')}>
            <span
              style={{ marginLeft: 8 }}
              onClick={() => handleDelete(record)}>
              <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Button
        type='primary'
        style={{ marginBottom: '1rem' }}
        onClick={handleNewMessage}>
        {t('New Message')}
      </Button>
      <Table
        loading={loading}
        size='middle'
        dataSource={message.list}
        scroll={{ x: 1200, y: 530 }}
        columns={tableColumnsWithAction}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: message.count,
          pageSizeOptions: ['10', '20', '50', '100'],
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
      <MessageModal
        handleModalClose={handleModalClose}
        isModalVisible={isEditModalVisible}
        handleEditFormSubmit={handleEditFormSubmit}
        editingRecord={editingRecord}
      />
    </>
  );
};

export default TableComponent;
